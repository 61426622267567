import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n/helper';
import ShareSocial from '../../components/share/ShareSocial';

export default function Share(props) {
  const { slugId, shareURL } = props;
  return (
    <main className='l-section-main c-section-main' id='appContent' aria-live='polite'>
      <div className='l-container'>
        <div className='c-section-intro'>
          <h1 className='c-section-intro__title'>{i18n.t('Share')}</h1>
        </div>
        <ShareSocial slugId={slugId} shareURL={shareURL} classModifier='c-social--labels' />
      </div>
    </main>
  );
}

Share.propTypes = {
  slugId: PropTypes.string,
  shareURL: PropTypes.string,
};
