import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { getFaq as getFaqAction } from '../../actions';
import i18n from '../../i18n/helper';
import FaqItem from '../../components/more/FaqItem';

function Faq(props) {
  const { slugId, faqData, actions } = props;
  const { getFaq } = actions;

  const [toggleAll, setToggleAll] = useState(true);

  useEffect(() => {
    getFaq(slugId);
  }, [slugId, getFaq]);

  // console.log(faqData);

  return (
    <main className='l-section-main c-section-main' id='appContent' aria-live='assertive'>
      <div className='l-container'>
        <div className='c-section-intro c-section-intro--no-margin'>
          <h1 className='c-section-intro__title'>{i18n.t('FAQS')}</h1>
          <div className='c-section-intro__action'>
            <button
              className='c-button c-button--pill c-button--small c-button--alternate c-button--toggle'
              onClick={() => setToggleAll(!toggleAll)}
            >
              <span className='c-button__label'>
                {toggleAll ? i18n.t('general.collapseAll') : i18n.t('general.expandAll')}
              </span>
            </button>
          </div>
        </div>
        <div className='u-a-children-fade-in'>
          {faqData.data.map((item, index) => (
            <FaqItem
              answer={item.answer}
              question={item.question}
              link={item.hyperlink}
              linkTitle={item.hyperlinkDescription}
              key={index}
              index={index}
              toggleState={toggleAll}
            />
          ))}
        </div>
      </div>
    </main>
  );
}

Faq.propTypes = {
  slugId: PropTypes.string,
  faqData: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  faqData: state.faq,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getFaq: getFaqAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
