import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useShowExplainer } from 'utils/hooks/useShowExplainer/useShowExplainer';
import SinglePoll from '../components/polls/SinglePoll';
import CharityExplainer from '../components/gamefication/charityExplainer/Explainer';
import PollsTitleBar from '../components/polls/PollsTitleBar';
import {
  getPolls as getPollsAction,
  tempAuthToken,
  retrievePoll,
  retrieveCharities,
  isGeolocationVerified,
  validateLocation,
  // clearPollsBadge,
  // getBadgeCountForAll,
} from '../actions';
import ShowSpinner from '../components/spinner/ShowSpinner';
import FeedbackOnPolls from '../components/polls/FeedbackOnPolls';
import Panel from '../components/panel/Panel';
import Questions from './questions/Questions';
import { getActiveCharities } from '../utils/utils';

import { AT_1007_CHARITY, AT_1013_KNOW_LOCATION, AT_1016_WRONG_LOCATION } from './questions/QuestionConstants';
import { getQueries } from '../components/link/LinkHelper';

/**
 * IMPORTANT
 * Poll popup opens by observing pollId change in the URL
 * So activating a poll is done by setting pollId in the URL (and not calling directly openPoll function)
 * This is to address https://github.com/sdesregistry/IH-Engage/issues/781
 * Things get complicated because we have to support also preview mode and opening particular question
 */
function Polls(props) {
  const {
    actions,
    slugId,
    history,
    domain,
    location,
    match,
    initialQuestion,
    charities,
    // isPollsOnly,
    isLocationVerified,
    customizations,
  } = props;
  // const { actions, slugId, history, domain, location, match, initialQuestion, charities, customizations } = props;

  // STATE
  const [polls, setPolls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [questionForm, setQuestionForm] = useState({
    showPoll: false,
    initialFormType: null,
  });
  const [showExplainer, setShowExplainer] = useShowExplainer();

  useEffect(() => {
    actions.retrieveCharities(slugId);
    if (domain.isActivated) {
      actions.isGeolocationVerified(slugId);
    }
  }, [actions, domain.isActivated, slugId]);

  useEffect(() => {
    setIsLoading(true);
    // console.log('MOUNT POLLS');
    actions.getPolls(slugId).then((res) => {
      setPolls(res);
      setIsLoading(false);
    });

    // returned function will be called on component unmount
    // return () => {};
    // !!!! IMPORTANT Not clear if this is correct to silence the warnings
    // Maybe I'm doing something wrong here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, slugId]);

  function getPolls() {
    return polls.polls || [];
  }

  function getTrimmedPath() {
    let trimmedPath;
    if (location.pathname) {
      const array = location.pathname.split('/');
      trimmedPath = `/${array[0]}${array[1]}/${array[2]}`;
    }
    return trimmedPath;
  }

  function isCharityChosen(pollId) {
    return polls.isCharityChosen && polls.isCharityChosen[pollId];
  }

  /**
   * Load a poll from API and open popup
   * @param pollId
   */
  function loadAndOpenPoll(pollId) {
    // load poll from API
    // if (domain.isActivated && !isLocationVerified) {
    //   setQuestionForm({
    //     showPoll: true,
    //     initialFormType: AT_1016_WRONG_LOCATION,
    //   });
    //   return;
    // }
    actions
      .retrievePoll(slugId, pollId)
      .then(() => {
        setQuestionForm({
          showPoll: true,
          initialFormType: null,
        });
        window.gtag('event', 'startedPoll', { event_category: 'polls', event_label: pollId });
      })
      .catch((err) => {
        // console.log('400', err);
        if (err.status === 400) {
          setQuestionForm({
            showPoll: true,
            initialFormType: AT_1016_WRONG_LOCATION,
          });
        }
      });
  }

  /**
   * Close poll popup and housekeeping
   */
  function closePoll() {
    if (customizations.hideClosePollButton) {
      return;
    }
    history.push(`${getTrimmedPath()}${getQueries()}`);
    // dispatch close poll message
    setQuestionForm({
      showPoll: false,
      initialFormType: null,
    });
  }

  function getPollClassModifier(index) {
    const wideModifier = getPolls().some((item) => !item.resource) ? 'c-card-poll--wide' : '';
    const firstItemOddModifier =
      getPolls().length % 2 && getPolls().some((item) => item.resource) && getPolls()[0].resource
        ? 'c-card-poll--floating'
        : '';
    const classModifier = `${wideModifier} ${index === 0 ? firstItemOddModifier : ''}`;
    return classModifier;
  }

  function pollClick(pollId) {
    setQuestionForm({
      showPoll: false,
      initialFormType: null,
    });
    history.push(`${getTrimmedPath()}/${pollId}${getQueries()}`);
  }

  /**
   * Open poll action
   * http://localhost:3010/colosseum/polls/5c476a5c1011b000049ffade?isPreview=true&question=5c4777195f88d100047789f2
   */
  async function openPoll(pollId, isFinished) {
    // console.log('PollClick', isFinished, pollId, polls);

    // If poll is finished either ignore or pick charity
    if (isFinished) {
      const activeCharities = getActiveCharities(charities);
      // poll finished and charity chose - just do nothing

      if (isCharityChosen(pollId) || !activeCharities.length) {
        return;
      }

      // If poll completed, but charity not picked
      // open poll popup on charity picker
      // console.log('Finished so I have to show charity picker');
      setQuestionForm({
        showPoll: true,
        initialFormType: AT_1007_CHARITY,
      });
      return;
    }

    // Check if we need geofencing
    if (!domain.isActivated || isLocationVerified) {
      // if not open poll
      loadAndOpenPoll(pollId);
    } else {
      // ask for location permission
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // store location and open poll
          localStorage.setItem('Geo-Position', `${position.coords.latitude};${position.coords.longitude}`);
          actions
            .validateLocation(slugId, {
              locationData: { lat: position.coords.latitude, lng: position.coords.longitude },
            })
            .then((_isLocationVerified) => {
              // console.log('_isLocationVerified', _isLocationVerified);
              // issue https://github.com/sdesregistry/IH-Engage/issues/1871#issuecomment-1337266531
              // use directly the return value from the call because the isLocationVerified state
              // variable is not available until the next tick
              if (_isLocationVerified) {
                loadAndOpenPoll(pollId);
              } else {
                setQuestionForm({
                  showPoll: true,
                  initialFormType: AT_1016_WRONG_LOCATION,
                });
              }
            });
        },
        () => {
          // Notify wrong location if permission not given
          setQuestionForm({
            showPoll: true,
            initialFormType: AT_1013_KNOW_LOCATION,
          });
        },
      );
    }
  }

  useEffect(() => {
    // console.log('POLLS', polls);
    const preselectedPoll = polls.polls && polls.polls.find((item) => item._id === match.params.id);
    if (preselectedPoll) {
      openPoll(preselectedPoll._id, preselectedPoll.isFinished);
    }

    // return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polls, match.params.id]);

  return (
    <main className='l-section-main c-section-main' id='appContent' aria-live='polite'>
      <div className='l-container'>
        {polls.panel && <Panel history={props.history} panel={polls.panel} />}
        {getPolls().length > 0 && (
          <React.Fragment>
            <PollsTitleBar
              openExplainer={() => setShowExplainer(!showExplainer)}
              showExplainer={showExplainer}
              explainerVisible={charities.length > 0 && charities.filter((charity) => charity.isActive).length > 0}
            />
            {showExplainer && (
              <CharityExplainer showExplainer={showExplainer} setShowExplainer={setShowExplainer} slugId={slugId} />
            )}
            <div className='l-grid-polls u-a-children-fade-in'>
              <ShowSpinner showSpinner={isLoading} />
              {!isLoading &&
                !customizations.hidePollList &&
                getPolls().map((poll, index) => (
                  <SinglePoll
                    key={index}
                    name={poll.name}
                    questionCount={poll.questionCount}
                    isFinished={poll.isFinished}
                    completedQuestions={poll.completedQuestions}
                    coins={charities.filter((charity) => charity.isActive).length > 0 ? poll.coins : 0}
                    resource={poll.resource}
                    onClick={() => pollClick(poll._id)}
                    classModifier={getPollClassModifier(index)}
                  />
                ))}
              {/* Feedback */}
              <FeedbackOnPolls history={history} />
            </div>
          </React.Fragment>
        )}

        {questionForm.showPoll && (
          <Questions
            slugId={slugId}
            initialQuestion={initialQuestion}
            closePoll={closePoll}
            nextPoll={pollClick}
            initialFormType={questionForm.initialFormType}
          />
        )}
      </div>
    </main>
  );
}

Polls.propTypes = {
  slugId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  charities: state.charities.data,
  isLocationVerified: state.geolocation.isLocationVerified,
  customizations: state.customizations,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPolls: getPollsAction,
      tempAuthToken,
      retrievePoll,
      retrieveCharities,
      isGeolocationVerified,
      validateLocation,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Polls);
