import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '../SvgIcon';
import ImageOrVideo from '../imageOrVideo/ImageOrVideo';
import { getType, stageTypes } from './StageUtils';
import MarkdownField, { MARKDOWN_MID } from '../markdown/MarkdownField';

const StageHeader = (props) => {
  const { title, date, text } = props;
  return (
    <React.Fragment>
      <h3 className='c-stage__title'>{title}</h3>
      <p className='c-stage__meta'>
        {date}
        <span className='c-stage__tag'>{text}</span>
      </p>
    </React.Fragment>
  );
};

export default function Stage(props) {
  const { title, description, resource, date, phaseNumber, stageNumber, type } = props;

  const [isOpened, setIsOpened] = useState(stageTypes.IN_PROGRESS === type);
  const stageType = getType(type);
  const isThereContent = description || resource;

  return (
    <article
      className={`c-stage ${isThereContent ? 'c-stage--switchable' : ''} c-stage--${stageType.classModifier} ${
        isOpened ? 'is-opened' : 'is-closed'
      }`}
    >
      <div className='c-stage__status'>
        <SvgIcon icon={stageType.icon} />
      </div>
      <div className='c-stage__group' aria-live='assertive'>
        <header className='c-stage__header' id={`stage-${phaseNumber}-${stageNumber}-header`}>
          {isThereContent ? (
            <button
              className='c-stage__toggle'
              aria-controls={`stage-${phaseNumber}-${stageNumber}-content`}
              aria-expanded={!!isOpened}
              onClick={() => setIsOpened(!isOpened)}
            >
              <StageHeader title={title} date={date} text={stageType.text} />
              <span className='c-stage__toggle-indicator'>
                <SvgIcon icon='angle-down' />
              </span>
            </button>
          ) : (
            <StageHeader title={title} date={date} text={stageType.text} />
          )}
        </header>
        {isThereContent && (
          <section
            className='c-stage__content u-rich-text'
            id={`stage-${phaseNumber}-${stageNumber}-content`}
            aria-labelledby={`stage-${phaseNumber}-${stageNumber}-header`}
            aria-hidden={!isOpened}
          >
            {resource && (
              <figure className='c-stage__figure'>
                <ImageOrVideo resource={resource} alt='' />
              </figure>
            )}
            <MarkdownField markdown={description} markdownConfig={MARKDOWN_MID} />
          </section>
        )}
      </div>
    </article>
  );
}

Stage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  resource: PropTypes.object,
  date: PropTypes.string.isRequired,
  phaseNumber: PropTypes.number,
  stageNumber: PropTypes.number,
  type: PropTypes.string.isRequired,
};
