import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MarkdownField, { MARKDOWN_MAX } from '../markdown/MarkdownField';
import SvgIcon from '../SvgIcon';
import PillButton from '../button/PillButton';

export default function FaqItem(props) {
  const { link, linkTitle, answer, question, index, toggleState } = props;

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setIsOpened(toggleState);
  }, [toggleState]);

  return (
    <article className={`c-faq c-faq--switchable ${isOpened ? 'is-opened' : 'is-closed'}`} aria-live='assertive'>
      <div className='c-faq__header' id={`faq-${index}-header`}>
        <button
          className='c-faq__toggle'
          aria-controls={`faq-${index}-content`}
          aria-expanded={isOpened}
          onClick={() => setIsOpened(!isOpened)}
        >
          <h3 className='c-faq__title'>{question}</h3>
          <span className='c-faq__toggle-indicator'>
            <SvgIcon icon='angle-down' />
          </span>
        </button>
      </div>
      <div
        className='c-faq__content u-rich-text'
        id={`faq-${index}-content`}
        aria-labelledby={`faq-${index}-header`}
        aria-hidden={!isOpened}
      >
        <MarkdownField markdown={answer} markdownConfig={MARKDOWN_MAX} />
        {link && (
          <div className='c-faq__action'>
            <PillButton url={link} title={linkTitle} classModifier='c-button--alternate' />
          </div>
        )}
      </div>
    </article>
  );
}

FaqItem.propTypes = {
  index: PropTypes.number,
  question: PropTypes.string,
  answer: PropTypes.string,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  toggleState: PropTypes.bool,
};
