import React, { useState } from 'react';

import i18n from '../../../i18n/helper';
import { OTHER_CHOICE_IMAGE } from '../../../constants';
import Image from '../Image';
import { WIDE } from '../../images/Img';
import { handleScroll } from '../QuestionUtils/QuestionFormUtils';

export default function Question15(props) {
  const { question, selected, showCoin, submitImageChoice, goToSubmitFeedback } = props;
  const [isScrollingClass, setScrollingClass] = useState('');
  // console.log(showCoin, selected);

  const otherChoiceId = question.choices.find((item) => !item.resource)._id;

  const showAnimation = (choiceId) => showCoin && choiceId === selected;

  // create array with other choice fixed to last position
  const newItems = question.choices.filter((item) => item.resource);
  newItems.push(question.choices.find((item) => !item.resource));
  return (
    <article className={`c-question c-question--wide ${isScrollingClass}`}>
      <div className='c-question__header'>
        <h2 className='c-question__title'>{question.questionText}</h2>
      </div>

      <div className='c-question__content' onScroll={handleScroll(setScrollingClass)}>
        <div className='l-choice-grid l-choice-grid--wide-three u-a-children-fade-in'>
          {newItems.map((data, index) => (
            <Image
              idx={index}
              key={data._id}
              onImageClick={index === 2 ? () => goToSubmitFeedback(otherChoiceId) : () => submitImageChoice(data._id)}
              resource={index === 2 ? OTHER_CHOICE_IMAGE : data.resource}
              isFixedImageURL={index === 2}
              showCoin={showAnimation(data._id)}
              imageText={index === 2 ? i18n.t('questions.imagesOther.question.other') : data.choice}
              imageType={WIDE}
              questionId={question._id}
              isDisabled={!!selected}
            />
          ))}
        </div>
      </div>
    </article>
  );
}
