import React from 'react';
import I18n from '../../i18n/helper';
import NavItem from './NavbarItem';

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyClass: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onWindowScroll, false);
  }

  // UNSAFE_componentWillReceiveProps(newProps) {
  //   this.setState(
  //     {
  //       fromApp: !newProps.fromApp,
  //     },
  //     () => this.props.actions.getAboutData(this.props.slug_id),
  //   );
  // }

  onWindowScroll = () => {
    // breaking if header is not present (survey on separate page)
    if (!document.getElementById('appHeader')) {
      return;
    }

    const headerHeight = document.getElementById('appHeader').offsetHeight;
    const stickyClass = window.pageYOffset > headerHeight ? 'is-sticky' : '';
    this.setState({ stickyClass });
  };

  render() {
    const slug = sessionStorage.getItem('slug');
    // console.log('COUNT', this.props.timelineCount);
    return (
      <nav className={`l-section-nav c-section-nav ${this.state.stickyClass}`} id='appNav'>
        <div className='l-container'>
          <nav className='c-nav-header' aria-label='Main navigation'>
            <ul className='c-nav-header__items'>
              <NavItem
                to={`/${slug}`}
                label={I18n.t('header.home')}
                icon='nav-home'
                exact
                classNameModifier='c-nav-header__item--home'
              />
              <NavItem to={`/${slug}/timeline`} label={I18n.t('header.timeline')} icon='nav-timeline' />
              <NavItem to={`/${slug}/news`} label={I18n.t('header.news')} icon='nav-news' />
              <NavItem to={`/${slug}/surveys`} label={I18n.t('header.polls')} icon='nav-polls' />
              <NavItem to={`/${slug}/more`} label={I18n.t('header.more')} icon='nav-more' />
            </ul>
          </nav>
        </div>
      </nav>
    );
  }
}

export default NavBar;
