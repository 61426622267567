import { Fragment, useEffect, useState } from 'react';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import i18n from 'i18n/helper';
import { getFeedbakOptions } from 'actions';
import { createAnswer } from 'components/organisms/question/utils';
import QuestionFooter from 'components/question/questionComponents/QuestionFooter';
import { TextArea } from 'components/atoms/textArea/TextArea';
import { getReactionImageUrl, IMAGE_FORMATS } from 'components/images/Formats';
import { ImageWithZoomOrCarousel } from 'components/molecules/imageWithZoomOrCarousel/ImageWithZoomOrCarousel';
import { QuestionTextHeader } from 'components/atoms/questionTextHeader';
import { MAX_FEEDBACK_CHARS } from '../../../../constants';
import { QuestionComponentProps } from '../types';
import { FeedbackState } from '../annotateAdvanced/types';

export const RatingQuestionFn = ({
  question,
  isResult,
  actions,
  slugId,
  onSubmitQuestion,
  userData,
  feedbackEmailZip,
  questionButtonClicked,
  showCoin,
  setIsMediaZoomed,
  isMediaZoomed,
  disableForm,
}: PropsFromRedux & QuestionComponentProps): JSX.Element => {
  const { questionText, multiChoicesOfferedBound, choices, questionConfiguration, resource } = question;
  const { allowComments, ratingConfiguration, leadImageType } = questionConfiguration;
  const { useCustomLabels } = ratingConfiguration;
  const [feedback, setFeedback] = useState('');
  const [amount, setAmount] = useState(0);

  const numberArray = Array.from(Array(multiChoicesOfferedBound).keys()).map((item) => item + 1);

  useEffect(() => {
    actions.getFeedbakOptions(slugId);
  }, [actions, slugId]);

  const onHandleButton = () => {
    if (!isResult) {
      onSubmitQuestion(
        createAnswer([
          {
            choiceId: choices[0]._id,
            feedback,
            email: feedbackEmailZip.email || userData.email || '',
            postalCode: feedbackEmailZip.postCode || userData.postCode || '',
            amount,
          },
        ]),
      );
    } else {
      questionButtonClicked();
    }
  };

  return (
    <article className='c-question c-question--narrow'>
      <QuestionTextHeader
        questionText={questionText}
        isResult={isResult}
        resultText={i18n.t('questions.dragDropQuestionScreen.result')}
      />
      <div className='c-question__content'>
        <div
          className={`c-rating c-rating--items-${multiChoicesOfferedBound} ${
            resource.length !== 0 ? 'has-lead-image' : ''
          } ${allowComments ? 'has-feedback-form' : ''}`}
        >
          {resource.length !== 0 && (
            <ImageWithZoomOrCarousel
              resource={resource}
              altImage='rating image'
              leadImageType={leadImageType}
              setIsMediaZoomed={setIsMediaZoomed}
              zoomEnabled={!disableForm}
            />
          )}
          <div className='c-rating__group' style={{ display: `${isMediaZoomed ? 'none' : ''}` }}>
            <div className='c-rating__control'>
              <input
                value='0'
                id='rating--0'
                type='checkbox'
                name='rating'
                className='visuallyhidden rating-item--0'
                defaultChecked
              />
              <label htmlFor='rating--0' className='rating-item--0'>
                <span className='visuallyhidden'>0</span>
              </label>
              {numberArray.map((item) => (
                <Fragment key={item}>
                  <input
                    value={item}
                    id={`rating--${item}`}
                    type='checkbox'
                    name='rating'
                    className='visuallyhidden'
                    checked={item <= amount}
                    onChange={(e) => setAmount(parseInt(e.target.value, 10))}
                    disabled={isResult}
                  />
                  <label
                    htmlFor={`rating--${item}`}
                    style={
                      choices[0].resource
                        ? {
                            backgroundImage: `url(${getReactionImageUrl(
                              choices[0].resource,
                              // 'rating_00',
                              IMAGE_FORMATS.GMV_IMAGE_SPRITE,
                            )})`,
                          }
                        : {}
                    }
                  >
                    <span className='visuallyhidden'>{item}</span>
                  </label>
                </Fragment>
              ))}
            </div>
            <div className='c-rating__labels c-rating__labels--numbered'>
              <ol className='c-rating__labels-container'>
                {useCustomLabels ? (
                  <Fragment>
                    <span className='c-rating__label-item-low'>{ratingConfiguration.resourceLabels?.lowest ?? ''}</span>
                    <span className='c-rating__label-item-high'>
                      {ratingConfiguration.resourceLabels?.highest ?? ''}
                    </span>
                  </Fragment>
                ) : (
                  <Fragment>
                    {numberArray.map((item) => (
                      <li key={item} className='c-rating__label-item'>
                        {item}
                      </li>
                    ))}
                  </Fragment>
                )}
              </ol>
            </div>
          </div>
          {allowComments && (
            <div className='l-form c-fields' style={{ display: `${isMediaZoomed ? 'none' : ''}` }}>
              <TextArea
                id='rating-question-feedback'
                value={feedback}
                setValue={setFeedback}
                placeholder={i18n.t('questions.feedbackForm.textareaPlaceholder')}
                label={i18n.t('questions.feedbackForm.textareaLabel')}
                maxLength={MAX_FEEDBACK_CHARS}
                disabled={isResult}
              />
            </div>
          )}
        </div>
      </div>
      <QuestionFooter
        showCoin={showCoin}
        buttonText={!isResult ? i18n.t('questions.submitButton') : i18n.t('questions.nextQuestion')}
        onClick={onHandleButton}
        disabled={amount === 0 || disableForm}
        buttonClassModifier='c-button--primary c-button--large'
      />
    </article>
  );
};

const mapStateToProps = (state: FeedbackState) => ({
  userData: state.feedback.userData,
  feedbackEmailZip: state.polls.feedbackEmailZip,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  actions: bindActionCreators(
    {
      getFeedbakOptions,
    },
    dispatch,
  ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export const RatingQuestion20 = connector(RatingQuestionFn);

type PropsFromRedux = ConnectedProps<typeof connector>;
